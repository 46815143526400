import React from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function App() {
  useEffect(() => {
    document.title = 'Epoq AI';
  }, []);

  return (
    <>
      <div className="content">
        <a href="index.html" className="logo">
          <img
            src={require('./images/logofull.png')}
            style={{ width: '100%' }}
            alt="logo"
          />
        </a>
        {/* <a href="index.html" className="logo">
          <img
            src={require('./images/logoch.png')}
            className="w-full"
            alt="logo"
          />
        </a> */}
        <div className="hero">
          {/* <h2 className="title">Boost Profits with AI</h2>
          <h2 className="title">Turn Customer Data into Growth</h2> */}
          {/* <h1 className="title shift clip">
            <span className="word-shift">
              <b className="is-visible">Money on the table</b>
              <b>minimalists</b>
              <b>indie hackers</b>
              <b>mindful people</b>
              <b>designers</b>
              <b>side project junkies</b>
              <b>people who want less</b>
            </span>
          </h1> */}
          <h2 style={{ marginTop: 12 }}>Coming Soon</h2>
          <Link
            to="#"
            onClick={(e) => {
              window.location.href = 'mailto:founders@epoq.ai';
              e.preventDefault();
            }}
            className="button modal-toggle"
          >
            Join Our Waitlist
          </Link>
          <div
            style={{
              marginTop: 16,
              display: 'flex',
              gap: 8,
              alignItems: 'center'
            }}
          >
            <img
              src={require('./images/mail.png')}
              style={{ width: 24, height: 24, marginBottom: 3 }}
            />
            founders@epoq.ai
          </div>
        </div>
        <section
          style={{
            padding: '48px',
            backgroundColor: '#e1efd2'
          }}
        >
          <h1 className="title aboutUs">About Us</h1>
          <div className="founderSection">
            <div className="founder">
              <div className="founderPrimary">
                <h2 className="founderName">Shaswat Shah</h2>
                <a
                  style={{ margin: 'auto 0' }}
                  href="https://www.linkedin.com/in/shaswatshah/"
                  target="_blank"
                >
                  <img
                    src={require('./images/linkedin.png')}
                    className="linkedInLogo"
                  />
                </a>
              </div>
              <p className="founderDescription" style={{ textAlign: 'left' }}>
                Shaswat was the tech lead for the Rider App at Lyft, where he
                developed models and infrastructure for personalization and
                dynamic pricing. He holds an M.B.A. from IIM Bangalore and an
                M.S. from Georgia Tech.
              </p>
            </div>
            <div className="founder">
              <div className="founderPrimary">
                <h2 className="founderName">Haoliang Zhang</h2>
                <a
                  style={{ margin: 'auto 0' }}
                  href="https://www.linkedin.com/in/haoliangzhang/"
                  target="_blank"
                >
                  <img
                    src={require('./images/linkedin.png')}
                    className="linkedInLogo"
                  />
                </a>
              </div>
              <p className="founderDescription" style={{ textAlign: 'left' }}>
                Haoliang was the founding engineer at Sorare NBA/MLB, along with
                building the core product, he led US sports customer engagement
                and acquisition. He was also the founding tech lead for the Lyft
                contextual home screen. He holds a B.S. from USC.
              </p>
            </div>
          </div>
        </section>
        {/* <div className="screen-wrapper">
          <img
            src={require('./images/screen.png')}
            className="screen"
            alt="screen"
          />
        </div> */}
        {/* <section className="section section-features">
          <h2 className="title">
            An intentionally dead simple email experience
          </h2>
          <div className="features">
            <img
              src={require('./images/overlay-left.svg')}
              className="overlay-left hide-mobile"
              alt="overlay"
            />
            <img
              src={require('./images/overlay-right.svg')}
              className="overlay-right hide-mobile"
              alt="overlay"
            />
            <img
              src={require('./images/overlay-top.svg')}
              className="overlay-top show-mobile"
              alt="overlay"
            />
            <img
              src={require('./images/overlay-bottom.svg')}
              className="overlay-bottom show-mobile"
              alt="overlay"
            />
            <div className="row donts">
              <div className="feature">
                <i className="icon" data-feather="x"></i> No integrations
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No templates
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No autoresponders
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No reminders
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No rules
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No filters
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No A.I.
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No snoozing
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No integrations
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No templates
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No autoresponders
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No reminders
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No rules
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No filters
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No A.I.
              </div>
              <div className="feature">
                <i className="icon" data-feather="x"></i> No snoozing
              </div>
            </div>
            <div className="row dos">
              <div className="feature">
                <i className="icon" data-feather="check"></i>Shortcuts
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Unified inbox
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Dark mode
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Lightning fast
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Beautiful imagery
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Simple UI
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Interactive
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Themes
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Shortcuts
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Unified inbox
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Dark mode
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Lightning fast
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Beautiful imagery
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Simple UI
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Interactive
              </div>
              <div className="feature">
                <i className="icon" data-feather="check"></i>Themes
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="section section-providers">
          <h2 className="title">
            Use with any and all of your email providers
          </h2>
          <div className="cards">
            <img
              src={require('./images/overlay-left.svg')}
              className="overlay-left hide-mobile"
              alt="overlay"
            />
            <img
              src={require('./images/overlay-right.svg')}
              className="overlay-right hide-mobile"
              alt="overlay"
            />
            <div className="slider">
              <div className="slide">
                <img src={require('./images/card-icloud.svg')} alt="icloud" />
              </div>
              <div className="slide">
                <img src={require('./images/card-gmail.svg')} alt="gmail" />
              </div>
              <div className="slide">
                <img src={require('./images/card-outlook.svg')} alt="outlook" />
              </div>
              <div className="slide">
                <img src={require('./images/card-yahoo.svg')} alt="yahooe" />
              </div>
              <div className="slide">
                <img src={require('./images/card-pop3.svg')} alt="pop3" />
              </div>
              <div className="slide">
                <img src={require('./images/card-imap.svg')} alt="imap" />
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="section imagery">
          <h2 className="title">Celebrate Inbox Zero with beautiful imagery</h2>
          <img
            src={require('./images/overlay-bottom.svg')}
            className="overlay-bottom hide-mobile"
            alt="overlay"
          />
          <div className="zero-slider">
            <div className="slide">
              <img
                src={require('./images/screen-illo-1.png')}
                alt="inboxzero"
              />
            </div>
            <div className="slide">
              <img
                src={require('./images/screen-illo-2.png')}
                alt="inboxzero"
              />
            </div>
            <div className="slide">
              <img
                src={require('./images/screen-illo-3.png')}
                alt="inboxzero"
              />
            </div>
            <div className="slide">
              <img
                src={require('./images/screen-illo-4.png')}
                alt="inboxzero"
              />
            </div>
            <div className="slide">
              <img
                src={require('./images/screen-illo-5.png')}
                alt="inboxzero"
              />
            </div>
            <div className="slide">
              <img
                src={require('./images/screen-illo-6.png')}
                alt="inboxzero"
              />
            </div>
          </div>
        </section> */}
        {/* <section className="section cta">
          <div className="cta-content">
            <h1 className="title">
              Join the beta, and start loving email again.
            </h1>
            <button className="button white modal-toggle">
              Join the waitlist
            </button>
            <p className="subtitle">
              Available soon for Mac{' '}
              <img src={require('./images/logo-apple.svg')} alt="apple" />
            </p>
          </div>
          <div className="zero-slider">
            <div className="slide">
              <img src={require('./images/screen-cta-1.jpg')} alt="inboxzero" />
            </div>
            <div className="slide">
              <img src={require('./images/screen-cta-2.jpg')} alt="inboxzero" />
            </div>
            <div className="slide">
              <img src={require('./images/screen-cta-3.jpg')} alt="inboxzero" />
            </div>
          </div>
        </section> */}
        <footer className="footer">
          <p>&copy; Epoq AI 2024</p>
        </footer>
      </div>
    </>
  );
}
